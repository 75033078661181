.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    text-align: center;
    max-width: 120ch;
    padding: 25px 50px 75px 25px;
}

.main-text {
    font-family: "verdana", "sans-serif";
    color: rgb(90, 90, 90);
}


#main-heading {
    color: black;
    font-family: "verdana", "sans-serif";
}

.button {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.button:hover {
    transform: scale(1.1);
}

.button:active {
    opacity: 0.6;
}