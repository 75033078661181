* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.title {
    text-decoration: none;
    color: black;
}


.nav {
    background-color: rgb(100, 149, 237);
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 0 4rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav-item {
    opacity: 0.6;
    text-decoration: none;
    color: black;
    font-weight: bold;
    margin: 0.5rem;
}

.nav-item:hover {
    opacity: 1;
    font-weight: bold;
    text-decoration: underline;
    transform: scale(1.1);
}

.nav-item:active {
  color: orangered;
}

.title {
    color: darkgreen;
    text-decoration: none;
    width: 0;
    size: 2rem;
    border-right: 3px solid;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Courier New', Courier, monospace;
    animation: typing 2s steps(13, end) forwards, blink .5s step-end infinite alternate;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@media (max-width: 768px) {
    .nav-item {
        display: none;
    }
}

