body {
  background-color: rgb(179, 206, 229); 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 440px;
}

#root {
  overflow-x: visible;
  height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link {
  text-decoration: none;
  color: blue;
  opacity: 0.7;
}

.link:hover {
  opacity: 1;
  text-decoration: underline;
}

.link:visited {
  color: orangered;
}

span {
  color: rgb(90, 90, 90);
}

.btn {
  border-radius: 12px;
}

.btn:active {
  opacity: 0.6;
}